import React from "react";
import "./SellMyHome.scss";
import { Link } from "react-router-dom";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";

import { Box, Typography, Button } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';

import Listing1 from "../../assets/listing1.jpg";

const SellMyHome = (props) => {
  return (
    <Box>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main,
              }}
            >
              We buy As-is… and we mean it!
            </h1>
            <p
              className="heading-title l2"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark,
              }}
            >
              At CarpHomes, we believe every home has value. We’re ready to make you a fair, all-
              cash offer in the exact as-is condition of your home. You don’t need to worry about
              making repairs, cleaning, or even staging your home. We can save you time, money,
              and stress.
            </p>
          </div>
        </div>
      </section>
      {/* <div className="content-wrapper"> */}
      <Box mt={2.5}>
        <Box
          className="smh-heading-container"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box flex={1} m={2.5}>
            <Typography
              variant="h4"
              fontFamily={"karla"}
              mb={2.5}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.warning.main
                  : props.theme.palette.warning.main
              }
            >
              No Realtor Fees, No Closing Costs
            </Typography>
            <Typography
              lineHeight={2.5}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.common.white
                  : props.theme.palette.primary.dark
              }
            >
              When you sell your home to CarpHomes, you won’t pay any realtor commissions,
              and there are no hidden closing costs. No need for any complicated calculations or
              last-minute surprises at the closing table. With our offers, what you see is what you get!
            </Typography>
          </Box>
          <Box flex={1} m={2.5}>
            <Typography
              variant="h4"
              fontFamily={"karla"}
              mb={2.5}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.warning.main
                  : props.theme.palette.warning.main
              }
            >
              Local Service, Personal Touch
            </Typography>
            <Typography
              lineHeight={2.5}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.common.white
                  : props.theme.palette.primary.dark
              }
            >
              Being local means we care about the community and its people. Unlike big, national
              companies, we offer a personal, hands-on approach. A member of our team will meet
              you in person to discuss your needs and walk you through the process, ensuring that
              you feel comfortable and well-informed every step of the way. When you work with
              CarpHomes, you’re not just another transaction — you’re a valued neighbor.
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={3}
          mb={20}
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4'
          }
        >
          <div className="content-wrapper">

            <Box>
              <Box
                sx={{ width: "87.5%", margin: "0 auto", textAlign: "center" }}
              >
                <Typography
                  variant="h4"
                  fontFamily={"karla"}
                  mb={2.5}
                  mt={10}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.secondary.light
                      : props.theme.palette.primary.dark
                  }
                >
                  Why Choose Us?
                </Typography>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  Unlike traditional real estate agents, we possess a unique
                  skill set that sets us apart. Our founder, Travis Carpenter,
                  brings a wealth of expertise derived not only from the real
                  estate industry but also from his hands-on experience in home
                  renovation. With a deep understanding of the intricacies
                  involved in selling a fixer-upper property, we go beyond
                  merely listing your home. We meticulously assess its potential
                  and value from a renovator's perspective, enabling us to
                  accurately price your property and maximize its market appeal.
                </Typography>
              </Box>
              <Box padding={2} mt={5} borderRadius={25} mb={10}>
                <Typography
                  variant="h4"
                  textAlign="center"
                  fontFamily={"karla"}
                  fontSize={36}
                  mb={2.5}
                  mt={5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.warning.main
                      : props.theme.palette.warning.main
                  }
                >
                  Our Operations
                </Typography>
                <Timeline position="alternate-reverse">
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Comprehensive Home Evaluation
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        Our team will walk through your home with you to identify any
                        underlying issues or areas requiring improvement,
                        providing you with invaluable insights to make informed
                        decisions.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Accurate Pricing
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        Leveraging our dual expertise in real estate and
                        renovation, we determine optimal listing prices tailored
                        to your property's unique characteristics and potential
                        enhancements.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Strategic Marketing
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        We understand that every seller has specific preferences
                        and priorities. That's why we tailor our approach to
                        accommodate your individual needs, ensuring a seamless
                        and personalized selling process.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Customized Approach
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        We develop targeted marketing strategies to effectively
                        showcase the distinctive features and possibilities of
                        your fixer-upper home, attracting the right buyers while
                        transparently disclosing any pertinent information.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Box>
            </Box>
          </div>
        </Box>

      <div className="content-wrapper">
        <Box
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.light
              : props.theme.palette.secondary.main
          }
          sx={{
            borderRadius: "25px 25px 0 0",
          }}
        >
          <Box className="contact-us-text-container">
              <Typography variant="h4" fontFamily={"karla"}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.dark
                    : props.theme.palette.primary.main
                } textAlign={'center'}
              >
                Sell Your Indiana Home Fast with CarpHomes
              </Typography>
              <Typography lineHeight={2.5} mt={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                } textAlign={'center'}
              >
                We make selling your home easy, fast, and stress-free. As a local Indiana business, we offer cash purchases with no realtor commissions or closing costs. Close in as little as 7 days—no repairs, no open houses, no hassle. Enjoy a private, personalized experience with a team that understands your needs every step of the way.
              </Typography>

              <Box display="flex" justifyContent="center" mt={5}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor:
                      props.theme.palette.mode === "dark"
                        ? props.theme.palette.warning.main
                        : props.theme.palette.primary.main,
                  }}
                >
                  <Link className="link-item" to="/contact">
                    <Typography color={props.theme.palette.common.white}>
                      Reach Out
                    </Typography>
                  </Link>
                </Button>
              </Box>
            </Box>
          {/* Joy@Carphomes.net
              317-812-1388 */}
        </Box>
      </div>
      </Box>
      {/* </div> */}
      <Footer />
    </Box>
  );
};

export default SellMyHome;

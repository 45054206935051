import React from "react";
import "./Contact.scss";
import emailjs from "@emailjs/browser";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";
import Form from "../../components/Form/Form";
import { Box, Typography, TextField, Button } from "@mui/material";

import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';

const Contact = (props) => {

  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main,
              }}
            >
              Get in Touch with CarpHomes
            </h1>
            <p
              className="heading-title l2"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark,
              }}
            >
              We’re here to answer your questions and help you take the next step. Contact us today to start the process of selling your home quickly and hassle-free.            </p>
          </div>
        </div>
      </section>
      <div className="content-wrapper">
        <Box mt={20} mb={20}>
            <Form theme={props.theme} />
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;

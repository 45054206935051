import React from 'react';
import './FAQs.scss';
import { Link } from 'react-router-dom';

import Appbar from '../../components/Appbar/Appbar';
import Footer from '../../components/Footer/Footer';

import { Typography, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';

const FAQs = (props) => {
  console.log(props);
  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main,
              }}
            >
              Frequently Asked Questions
            </h1>
            <p
              className="heading-title l2"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark,
              }}
            >
              Explore our Frequently Asked Questions (FAQ's) to find answers to common inquiries about CarpHomes. If you can't find what you're looking for, reach out to our team for further assistance. We're here to ensure you have the information you need for a seamless experience with us.
            </p>
          </div>
        </div>
      </section>
      <div className='content-wrapper'>
        <Box padding={5}>
          <Box mt={10} mb={10}>
            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  How do you determine the optimal sale price for my home?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  Establishing the right sale price is a critical aspect of listing a property. Our methodology involves thorough market analysis, beginning with a review of recently sold, renovated homes in the neighborhood. We then assess the potential renovation expenses for your property and consider additional factors such as equity, closing costs, and commissions. Through this comprehensive approach, we arrive at a precise and competitive selling price tailored to your home's unique attributes and market conditions.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  What if my home requires extensive renovation?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  Rest assured, we have extensive experience handling properties of varying conditions, sizes, and styles. Whether your home requires minor cosmetic updates or significant structural renovations, we are equipped to handle the task. Our expertise spans from properties in need of minor cosmetic enhancements to those requiring extensive structural repairs. Regardless of the scope of work required, we are committed to providing comprehensive solutions tailored to meet your specific needs.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  How can we address concerns regarding privacy during showings and photography?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  We understand the importance of privacy and respect your individual preferences. Contrary to common misconceptions, there is no one-size-fits-all approach to listing a property. We work closely with you to develop a tailored plan that aligns with your unique circumstances. Whether you prefer limited showing dates and times or minimal photography of your home, we accommodate your preferences to ensure a comfortable and seamless selling experience.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  What if my home fails to meet inspection standards?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  We specialize in crafting listings tailored to accommodate the specific condition of your home. Various types of loans are available to buyers, including those designed for fixer-upper properties. We have the flexibility to customize your listing to attract buyers utilizing special construction loans or those willing to waive inspections. Additionally, we can facilitate offers on an 'As-Is' basis, ensuring that potential buyers are fully informed about your home's condition before making an offer.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.light
              : props.theme.palette.secondary.main
          }
          sx={{
            borderRadius: '25px 25px 0 0',
          }}
        >
          <Box className="contact-us-text-container">
            <Typography
              variant="h4"
              fontFamily={"karla"}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.warning.main
                  : props.theme.palette.primary.main
              }
              textAlign={'center'}
            >
              Sell Your Indiana Home Fast with CarpHomes
            </Typography>
            <Typography
              lineHeight={2.5}
              mt={2}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.common.white
                  : props.theme.palette.primary.dark
              }
              textAlign={'center'}
            >
              We make selling your home easy, fast, and stress-free. As a local Indiana business, we offer cash purchases with no realtor commissions or closing costs. Close in as little as 7 days—no repairs, no open houses, no hassle. Enjoy a private, personalized experience with a team that understands your needs every step of the way.
            </Typography>

            <Box display="flex" justifyContent="center" mt={5}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.warning.main
                      : props.theme.palette.primary.main,
                }}
              >
                <Link className="link-item" to="/contact">
                  <Typography color={props.theme.palette.common.white}>
                    Reach Out
                  </Typography>
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
      <Footer />
    </div>
  )
}

export default FAQs;